@import '~antd/dist/antd.less';

@font-family: 'Nunito';

@border-radius-base: 4px; // major border radius
@checkbox-border-radius: 4px;
@table-border-radius-base: 10px;
@card-radius: 20px;
@btn-border-radius-base: 20px;

@form-item-label-font-size: @font-size-lg;

@primary-color: #41693D; // primary color for all components
// @btn-border-style: none;
@link-color: #41693D; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 15px; // major text font size
@heading-color: #063e04; // heading text color
@text-color: #063e04; // major text color

@menu-item-active-bg : #8da58b59;
@menu-dark-item-active-bg: #6C966B;
@menu-dark-color: white;
@menu-dark-bg: #41693D;
@menu-dark-inline-submenu-bg: #41693D;

@process-tail-color: #b6b6b6;
@steps-background: #b6b6b6;

// not used
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

