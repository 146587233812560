@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

body {
  margin: 0;
  font-family: 'Nunito';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #063e04 !important;
}

p {
  font-weight: 300;
}

a {
  text-decoration: underline;
}

.error {
  color: red !important;
}

.success {
  color: #41693d !important;
}

.tiny-hr {
  width: 40px;
  border: 1px solid #063e04;
  margin: 15px 0px;
}

.light-hr {
  width: 250px;
  border: 1px solid #063e041f;
  margin: 15px 0px;
}

.leaflet,
.leaflet-container {
  height: 100%;
  width: 100%;
  z-index: 0 !important;
}

.center-tabs .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list {
  place-items: center !important;
}

.ant-tabs-tab .anticon {
  margin-right: 0px;
}

.search-buttons {
  text-overflow: ellipsis;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.leaflet-bar a {
  color: #41693d;
}

.big-uploader .ant-upload-list-picture-card-container {
  width: 150px;
  height: 150px;
}

.big-uploader .ant-upload.ant-upload-select-picture-card {
  width: 150px;
  height: 150px;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 300px;
  margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

.ant-upload-list {
  display: flex;
  flex-direction: column;
}

.ant-btn-link > span {
  text-decoration: underline;
}

.images-upload > .ant-upload {
  width: 100%;
  height: 200px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.image-uploader .ant-upload-list-picture-card-container {
  margin: 0;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 2px;
  border: none;
  border-radius: 4px;
}

li.ant-menu-item {
  padding-left: 30px !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline {
  border-top: 1px solid #f6f5f1;
  border-bottom: 1px solid #f6f5f1;
}

.ant-pagination-item > a {
  text-decoration: none !important;
}

.ant-steps-item-icon {
  border: none !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: white;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white;
}

.login {
  background-image: url('../assets/background/bg-header-home.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

.menu {
  display: block;
}

.drawer {
  display: none;
}

.button-drawer {
  display: none;
}

.content {
  flex: 1 1 200px;
}

.wrapper {
  min-height: 100vh;
  align-content: stretch;
  flex-flow: nowrap;
}

.sticky-menu {
  position: fixed;
  top: 12px;
  height: calc(100vh - 24px);
}

.flex-menu {
  color: white;
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
}

@media only screen and (max-width: 1050px) {
  .menu {
    display: none;
  }
  .drawer {
    display: block;
  }
  .button-drawer {
    display: block;
  }
  .content {
    flex: 1;
  }
  .wrapper {
    align-content: baseline;
    flex-flow: wrap;
  }

  .login-background {
    display: none;
  }

  .login-card {
    background-color: transparent !important;
  }
}

.table-wrap {
  display: block;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  min-height: 50vh;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.164);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e7e7e7;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

.title-button {
  display: inline-flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  padding: 0 0 8px;
}

.milestone-button {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}

.warning-text {
  color: red;
  font-style: italic;
  font-size: 14px
}